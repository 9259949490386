import React, { useState } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { rem } from "polished"
import getYouTubeID from "get-youtube-id"

import Button from "./button"
import MediaModalCarousel from "./media-modal-carousel"
import { ReactComponent as SvgChevronRight } from "../assets/images/icons/chevron-right.svg"
import { ReactComponent as SvgTwitch } from "../assets/images/social-icons/twitch.svg"

const Container = styled.ul`
  ${props =>
    props.layout === `horizontal` &&
    css`
      margin-top: ${rem(30)};
      /* display: flex;
      flex-wrap: wrap; */

      li {
        display: inline-block;
        margin: ${rem(10)} ${rem(5)} 0;
      }
    `}

  ${props =>
    props.textAlign === `center` &&
    css`
      justify-content: center;
      text-align: center;
    `}

  ${props =>
    props.layout === `vertical` &&
    css`
      margin-top: ${rem(35)};

      li:not(:last-child) {
        margin-bottom: ${rem(5)};
      }
    `}
`

const CallToActions = ({ cta, layout, ...rest }) => {
  if (!cta || !cta.length) return null

  const [modalVideoUrl, setModalVideoUrl] = useState(null)

  const buttonClick = e => {
    const { href } = e.currentTarget
    if (getYouTubeID(href, { fuzzy: false })) {
      e.preventDefault()
      setModalVideoUrl(href)
    }
  }

  return (
    <Container layout={layout || `horizontal`} {...rest}>
      {cta.map((item, i) => (
        <li key={i}>
          <Button
            data-track-click
            data-track-click-ga-category="CTA Button"
            data-track-click-ga-action={item.url}
            data-track-click-ga-label={item.title}
            color={item.url.includes(`twitch`) ? `twitch` : ``}
            to={item.url}
            onClick={buttonClick}
          >
            {item.url.includes(`twitch`) && <SvgTwitch aria-hidden="true" />}

            {!item.url.includes(`twitch`) && (
              <SvgChevronRight aria-hidden="true" />
            )}

            {item.title}
          </Button>
        </li>
      ))}

      {modalVideoUrl !== null && (
        <MediaModalCarousel
          data={[{ type: `video`, videoUrl: modalVideoUrl }]}
          hideCurrentSlideIndex={true}
          onRequestClose={() => setModalVideoUrl(null)}
        />
      )}
    </Container>
  )
}

CallToActions.propTypes = {
  cta: PropTypes.array,
  layout: PropTypes.string,
}

export default CallToActions
