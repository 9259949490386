import React, { useRef, useEffect } from "react"
import ReactPlayer from "react-player"
import PropTypes from "prop-types"
import styled from "styled-components"
import fitToParent from "fit-to-parent"

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Inner = styled.div`
  position: relative;

  > * {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Video = ({ url, isActive }) => {
  const containerRef = useRef()
  const innerRef = useRef()

  const setSize = () => {
    fitToParent({
      ratio: 16 / 9,
      element: innerRef.current,
      parentWidth: containerRef.current.offsetWidth,
      parentHeight: containerRef.current.offsetHeight,
    })
  }

  useEffect(() => {
    setSize()
    if (typeof window !== `undefined`) {
      window.addEventListener(`resize`, setSize, { passive: true })
    }
    return () => {
      if (typeof window !== `undefined`) {
        window.removeEventListener(`resize`, setSize, { passive: true })
      }
    }
  }, [])

  return (
    <Container ref={containerRef}>
      <Inner ref={innerRef}>
        <ReactPlayer
          url={url}
          width="100%"
          height="100%"
          playing={isActive}
          controls={true}
          config={{
            youtube: {
              playerVars: {
                // https://developers.google.com/youtube/player_parameters
                modestbranding: 1,
                rel: 0,
              },
            },
          }}
        />
      </Inner>
    </Container>
  )
}

Video.propTypes = {
  url: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
}

export default Video
