import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"

import background from "../assets/images/section-heading-bg.png"

const Container = styled.header`
  color: ${props => props.theme.colorWhite};
  text-transform: uppercase;
  position: relative;
`

const Background = styled.figure`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;

  > * {
    width: 100%;
    height: 100%;

    @media ${props => props.theme.mediumUp} {
      clip-path: polygon(100% 0, 100% 60%, 81% 60%, 80% 100%, 0 100%, 0 0);
    }
  }
`

const Inner = styled.div`
  ${props => props.theme.gridContainer()}

  position: relative;
  z-index: 3;

  span {
    padding-top: ${rem(10)};
    padding-bottom: ${rem(10)};
    line-height: 1;
    text-shadow: 0 2px ${rem(15)} rgba(0, 0, 0, 0.8);
    color: ${props => props.theme.colorWhite};
  }
`

const InnerInner = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;

  @media ${props => props.theme.mediumUp} {
    width: 60%;
  }

  @media ${props => props.theme.largeUp} {
    width: 48%;

    span {
      font-size: ${rem(28)};
      font-weight: 400;
      margin-top: ${rem(2)};
    }
  }

  @media ${props => props.theme.xxlargeUp} {
    width: 45%;
  }
`

const SectionHeader = ({ title, headingLevel, ...rest }) => {
  if (!title || !title.length) return null

  return (
    <Container {...rest}>
      <Background>
        {background && (
          <img src={background} alt={`hello`} role="presentation" />
        )}
      </Background>
      <Inner as={`h${headingLevel}`}>
        <InnerInner>
          <span className="styled-h3">{title}</span>
        </InnerInner>
      </Inner>
    </Container>
  )
}

SectionHeader.propTypes = {
  title: PropTypes.string,
  headingLevel: PropTypes.string,
}

export default SectionHeader
