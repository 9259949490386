import { useContext, useEffect, useState } from "react"
import { CarouselContext } from "pure-react-carousel"

const CarouselCurrentSlideProvider = ({ children }) => {
  const carouselContext = useContext(CarouselContext)
  const [currentSlide, setCurrentSlide] = useState(
    carouselContext.state.currentSlide
  )

  useEffect(() => {
    const onChange = () => {
      setCurrentSlide(carouselContext.state.currentSlide)
    }
    carouselContext.subscribe(onChange)
    return () => carouselContext.unsubscribe(onChange)
  }, [carouselContext])

  return children({ currentSlide })
}

export default CarouselCurrentSlideProvider
