import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"
import Img from "gatsby-image/withIEPolyfill"
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"

import Video from "./video"
import Modal from "../modal"
import CarouselCurrentSlideProvider from "../../utils/carousel-current-slide-provider"
import { ReactComponent as SvgChevronRight } from "../../assets/images/icons/chevron-right.svg"
import { ReactComponent as SvgSpinner } from "../../assets/images/spinner.svg"

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-top: ${rem(70)};

  .carousel {
    position: relative;

    &,
    &__slider,
    &__slider-tray-wrapper,
    &__slider-tray,
    &__slide,
    &__inner-slide {
      width: 100%;
      height: 100%;
      position: static;
    }

    &__slider-tray {
      will-change: transform;
    }

    &__inner-slide {
      position: relative;
    }

    &__back-button,
    &__next-button {
      width: ${rem(40)};
      height: ${rem(60)};
      padding: ${rem(5)};
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;

      @media ${props => props.theme.mediumDown} {
        display: none;
      }

      &[disabled] {
        display: none;
      }

      &:hover span {
        transform: scale(1.2);
      }

      &:active span {
        opacity: 0.5;
      }

      span,
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }

      span {
        transition: 0.2s ${props => props.theme.easingDefault};
        transition-property: transform, opacity;
      }
    }

    &__back-button {
      left: ${rem(10)};

      svg {
        transform: scale(-1);
      }
    }

    &__next-button {
      right: ${rem(10)};
    }
  }
`

const Figure = styled.figure`
  width: 100%;
  height: 100%;
  padding: 0 ${rem(60)} ${rem(60)};
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 2;

  @media ${props => props.theme.mediumDown} {
    padding-left: ${rem(10)};
    padding-right: ${rem(10)};
  }
`

const Image = styled.div`
  &,
  > * {
    width: 100%;
    height: 100%;
  }
`

const Caption = styled.figcaption`
  width: 100%;
  position: absolute;
  bottom: ${rem(10)};
  left: 0;
  display: flex;
  justify-content: center;
  pointer-events: none;

  > div {
    text-align: center;
    padding: ${rem(6)} ${rem(14)};
    background-color: rgba(0, 0, 0, 0.6);
  }
`

const Spinner = styled.div`
  width: ${rem(50)};
  height: ${rem(50)};
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`

const CurrentSlideIndex = styled.div`
  position: absolute;
  top: ${rem(10)};
  left: ${rem(10)};
  font-size: ${rem(12)};
`

const MediaModalCarousel = ({
  data,
  currentSlide,
  hideCurrentSlideIndex,
  onRequestClose,
}) => {
  const [sliderEl, setSliderEl] = useState(null)

  useEffect(() => {
    if (sliderEl) {
      sliderEl.instance.sliderElement.focus()
    }
  }, [sliderEl])

  return (
    <Modal onRequestClose={onRequestClose}>
      <Container>
        <CarouselProvider
          visibleSlides={1}
          totalSlides={data.length}
          currentSlide={currentSlide}
        >
          <CarouselCurrentSlideProvider>
            {({ currentSlide: currentSlideIndex }) => (
              <React.Fragment>
                <Slider ref={n => setSliderEl(n)}>
                  {data.map((media, i) => (
                    <Slide key={i} index={i}>
                      <Figure>
                        {media.type === `image` ? (
                          <Image>
                            <Img
                              fluid={
                                media.image.localFile.childImageSharp.fluid
                              }
                              alt={media.description}
                              objectFit="contain"
                            />
                          </Image>
                        ) : (
                          <Video
                            url={media.videoUrl}
                            isActive={currentSlideIndex == i}
                          />
                        )}

                        {media.description && (
                          <Caption>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: media.description,
                              }}
                            />
                          </Caption>
                        )}
                      </Figure>

                      <Spinner>
                        <SvgSpinner />
                      </Spinner>
                    </Slide>
                  ))}
                </Slider>

                <ButtonBack title="Previous slide" aria-label="Previous slide">
                  <span>
                    <SvgChevronRight />
                  </span>
                </ButtonBack>

                <ButtonNext title="Next slide" aria-label="Next slide">
                  <span>
                    <SvgChevronRight />
                  </span>
                </ButtonNext>

                {!hideCurrentSlideIndex && (
                  <CurrentSlideIndex>
                    {currentSlideIndex + 1}/{data.length}
                  </CurrentSlideIndex>
                )}
              </React.Fragment>
            )}
          </CarouselCurrentSlideProvider>
        </CarouselProvider>
      </Container>
    </Modal>
  )
}

MediaModalCarousel.propTypes = {
  data: PropTypes.array.isRequired,
  currentSlide: PropTypes.number,
  hideCurrentSlideIndex: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
}

export default MediaModalCarousel
